/* CONFIGURACIÓN COLORES DE BOOSTRAP DINÁMICOS */
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/mixins';
@import './functions-override';
$theme-colors: (
  'primary': var(--primary-color),
  'light': var(--text-color),
  'secondary': var(--secondary-color),
  'danger': var(--danger-color)
);

$font-size-base: 1rem;

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";
/*----------------------------------------------------------*/
/*----------------------------------------------------------*/

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

h1 {
  font-size: 1.37em !important;
}
h2 {
  font-size: 1.25em !important;
}
h3 {
  font-size: 1.125em !important;
}
h4 {
  font-size: 1em !important;
}

:host ::ng-deep  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--primary-color) ;
}

:host ::ng-deep  .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  background-color: #607d8b;
}


:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--primary-color) ;
}

.text-body {
  color: white;
}

.btn-outline-primary:hover {
  color: var(--text-color) !important;
}
.btn-primary:hover {
  background-color: var(--primary-color) !important;
}

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  /* STYLES GO HERE */
  background-color: var(--primary-color) !important;
}

.btn-secondary.active, .btn-secondary:active, .open > .dropdown-toggle.btn-secondary {
  /* STYLES GO HERE */
  background-color: var(--secondary-color) !important;
}

.text-primary {
  color: var(--text-color)
}

.text-menu-secondary {
  color: var(--text-secondary) !important;
}

.hover-grey:hover {
  color: var(--text-secondary) !important;
  background-color: #e5e5e5 !important;
}


body { margin: 0; font-family: var(--font), sans-serif; }
h1 {
  font-family: var(--font), sans-serif !important;
}
h2 {
  font-family: var(--font), sans-serif !important;
}
h3 {
  font-family: var(--font), sans-serif !important;
}
h4 {
  font-family: var(--font), sans-serif !important;
}
h5 {
  font-family: var(--font), sans-serif !important;
}

.imagen-fondo {
  padding-left: 10% !important;
  padding-right: 10% !important;
  background-image: url("/assets/web-estatica/inicio/fondo-naturaleza.webp");
  background-repeat:no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: 100% auto;
}

@media (max-width: 1000px) {
  .imagen-fondo {
    padding: 0 !important;
  }
}

.bg-white {
  background-color: var(--background-color) !important;
}


h5 span {
  background-color: var(--background-color) !important;
  padding: 0 10px;
  font-size: 15px;
}
